import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { ARTICLES } from "../../../_constants/constants";
import { SharedService } from "../../../_services/shared.service";
import { AuthenticationService } from "../../../_services/auth.service";
import { listenersService } from "../../../_services/listeners.service";
import HomeNavigationBar from "../home-navigation-bar/HomeNavigationBar";
import { StaticContentModal } from '../../../_components/shared/modals/static-content/StaticContentModal';
import { ImpersonateHeader } from '../../../_components/shared/impersonation-header/ImpersonationHeader'

import "./Header.scss";

class Header extends Component {
  sharedService = new SharedService();
  authenticationService = new AuthenticationService();
  isCustomerServiceSite = JSON.parse(localStorage.getItem('initialIds')).isCustomerSite;

  authChangedSubscription;

  state = {
    showPopup: false,
    staticContent: {
      title: '',
      content: ''
    },
    isUserLoggedIn: false,
    globlWebContents: {},
  };

  componentDidMount() {
    this.globalWC_getContent([
      ARTICLES.HEADER_PHONE_INFO,
      ARTICLES.CONTACT_US,
      ARTICLES.ABOUT_US
    ]);

    // Check if user is logged in
    this.setState({
      isUserLoggedIn: this.authenticationService.isUserLoggedIn()
    });

    // Append listeners
    this.authChangedSubscription = listenersService.authStateChange().subscribe(data => {
      this.setState(
        {
          isUserLoggedIn: data ? true : false
        },
        () => {
          data === null && this.props.history.push("/"); // Send user back to login.
        }
      );
    });

    window.addEventListener("resize", () => {
      // Set header accordingly for mobile and desktop
      let domObj = document.getElementById(`globalWC_${'login-header-phone'}`);
      domObj && (domObj.innerHTML = this.state.globlWebContents['login-header-phone']);
      domObj && (document.querySelector('.headerCallText a') && (document.querySelector('.headerCallText a').style.pointerEvents = 'none'));
    });
  }

  componentWillUnmount() {
    this.authChangedSubscription.unsubscribe();
    window.removeEventListener("resize", null)
  }

  globalWC_getContent = titles => {
    let { globalGroupId, domainGroupId } = JSON.parse(localStorage.getItem("initialIds"));
    let params = {
      globalGroupId: globalGroupId,
      domainGroupId: domainGroupId,
      titles: titles
    };

    this.sharedService.getContents(params).subscribe(response => {
      if (response.status === 200) {
        let webContents = {};
        response.data.items.forEach(element => {
          // Set header accordingly for mobile and desktop
          let domObj = document.getElementById(`globalWC_${element.title}`);
          domObj && (domObj.innerHTML = element.content);
          domObj && (document.querySelector('.headerCallText a') && (document.querySelector('.headerCallText a').style.pointerEvents = 'none'));
          webContents[element.title] = element.content;
        });
        this.setState({
          globlWebContents: webContents
        });
        // document.getElementById("globalWC_headerPhoneInfo").innerHTML = response.data.content;
      }
    });
  };

  toLogin = () => {
    this.props.history.push("/");
  };

  handleLogoClick = () => {
    let isCSPortal = JSON.parse(localStorage.getItem('initialIds')).isCustomerSite;
    if (!isCSPortal) {
      let siteUrl = JSON.parse(localStorage.getItem('initialIds')).brandDetail.primaryLogoAnchorLink;
      siteUrl && window.open(siteUrl);
    }
  };

  getAboutUs = () => {
    let { showAboutUs } = JSON.parse(localStorage.getItem("initialIds"));
    return showAboutUs;
  }

  getContactUs = () => {
    let { showContactUs } = JSON.parse(localStorage.getItem("initialIds"));
    return showContactUs;
  }

  handleStaticCotentClose = () => {
    this.setState({ showPopup: false })
  }

  render() {
    return (
      <>
        {this.props.globalContext.isCustomerServiceUser() && <ImpersonateHeader />}
        <div className='mainHeader'>
          {this.state.showPopup && (
            <StaticContentModal
              show={this.state.showPopup}
              hide={this.handleStaticCotentClose}
              title={this.state.staticContent.title}
              content={this.state.staticContent.content}
              {...this.props}
            />
          )}
          <div className='innerHeaderBody'>
            <div onClick={this.handleLogoClick} className='logo'>
              <img alt={""} src={process.env.REACT_APP_ASSETS_BASE_URL + this.props.headerPrimaryLogo} />
              {this.props.globalContext.state.deviceWidth <= 767 &&
                <>
                  {this.props.headerSecondaryLogo && <span className='devider devider-header-logos'></span>}
                  <img className="secondary-logo-mobile" alt='' src={process.env.REACT_APP_ASSETS_BASE_URL + this.props.headerSecondaryLogo} />
                </>}
            </div>

            {this.props.globalContext.state.deviceWidth >= 768 && (
              <div className='headerRightColumn desktopView'>
                <div className='d-none d-sm-none d-md-block contactAbout'>
                  <span>
                    {this.getContactUs() &&
                      <Link
                        title={"Contact Us"}
                        to='/#'
                        onClick={e => {
                          e.preventDefault();
                          this.setState({
                            staticContent: {
                              title: 'Contact Us',
                              content: this.state.globlWebContents[ARTICLES['CONTACT_US']]
                            },
                            showPopup: true
                          });
                        }}>Contact Us</Link>
                    }

                  </span>
                  {this.getAboutUs() && this.getContactUs() && <span className='devider'></span>}
                  <span>
                    {
                      this.getAboutUs() &&
                      <Link
                        title={"About Us"}
                        to='#'
                        onClick={e => {
                          e.preventDefault();
                          this.setState({
                            staticContent: {
                              title: 'About Us',
                              content: this.state.globlWebContents[ARTICLES['ABOUT_US']]
                            },
                            showPopup: true
                          });
                        }}>About Us</Link>
                    }

                  </span>
                </div>
                {!this.isCustomerServiceSite && (
                  <div className='headerNavRight'>
                    <span className='d-none d-sm-none d-md-block'>
                      {this.props.globalContext.state.deviceWidth >= 768 && <span id={`globalWC_${ARTICLES.HEADER_PHONE_INFO}`} className='headerCallText'></span>}
                      <span className='rightSecLogo'>
                        <img alt='' src={process.env.REACT_APP_ASSETS_BASE_URL + this.props.headerSecondaryLogo} />
                      </span>
                    </span>
                  </div>
                )}
              </div>
            )}

            {this.props.globalContext.state.showNavigationBar && <HomeNavigationBar globlWebContents={this.state.globlWebContents} isUserLoggedIn={this.state.isUserLoggedIn} {...this.props} />}

          </div>
        </div>
      </>
    );
  }
}
export default withRouter(Header);
