
export const apiRequest = {
  base: process.env.REACT_APP_BASE_URL,

  baseForInitialIds: process.env.REACT_APP_BASE_INITIAL_ID,

  validationLocation: 'https://zip.getziptastic.com/v2/US',

  initialIds: '/agia-appcontent-service/api/v1/client-attribute',
  login: '/agia-appauth-service/api/v1/account/login',
  loginAttempts: '/o/authentication/v1.0/loginFail',
  getContent: '/o/webcontent/v1.0/getContentByName',
  getContents: '/agia-appcontent-service/api/v1/contents',
  resetPassword: '/o/authentication/v1.0/resetPassword',
  forgotPassword1: '/agia-appauth-service/api/v1/account/password',
  forgotPassword2: '/o/authentication/v1.0/forgotpassword',
  verifyPolicyNumber: '/agia-appauth-service/api/v1/account/policy-verification',
  verifyPersonalInfo: '/agia-appauth-service/api/v1/account/name-client-verification',
  verifySignInCreds: '/agia-appauth-service/api/v1/account/registration',
  forgotUsername: '/o/authentication/v1.0/forgotusername',
  changePassword: '/o/authentication/v1.0/changePassword',
  verifyCaptcha: '/agia-appauth-service/api/v1/security/bot',
  getProfile: '/myaccount-core-service/api/v1/profile',
  updateMyProfile: '/agia-appauth-service/api/v1/user/profile',
  getSecurityQuestions: '/o/userRegister/v1.0/getSecurityQuestions',
  updateSecurityDetails: '/o/authentication/v1.0/updateSecurityDetails',
  getPaymentMethods: '/myaccount-core-service/api/v1/payment/paymentMethods',
  getStatesList: '/agia-appcontent-service/api/v1/states?countryId=19',
  addPaymentMethod: '/myaccount-core-service/api/v1/payment/paymentMethod',
  updatePaymentMethod: '/myaccount-core-service/api/v1/payment/paymentMethod',
  deletePaymentMethod: '/myaccount-core-service/api/v1/payment/paymentMethodDeletion',
  getProducts: '/myaccount-core-service/api/v1/payment',
  dashboard: '/myaccount-core-service/api/v1/homepage',
  changeFrequencyAutopay: '/o/payments/v1.0/managePaymentPreference',
  submitPayNow: '/o/payments/v1.0/payNow',
  logout: '/agia-appauth-service/api/v1/account/logout',
  getProductsMain: '/myaccount-core-service/api/v1/product/summary',
  getProductDetails: '/myaccount-core-service/api/v1/product/details',
  searchClient: '/o/customer-service/v1.0/search/company-id/{companyId}/{search}/{value}/user-id/{userId}',
  impersonateUser: '/o/authentication/v1.0/impersonateUser/company-id/{companyId}/user-id/{userId}',
  deleteUser: '/o/userRegister/v1.0/deleteUser/company-id/{companyId}/username/{userId}',
  //viewDocument: '/o/products/v1.0/document/{documentId}/documentSource/{documentType}/documentCategory/{documentCategory}',
  viewDocument: '/myaccount-core-service/api/v1/product/document/{documentId}/documentSource/{documentType}/documentCategory/{documentCategory}',
  // unsubscribe: '/o/unsubscribe/v1.0',
  unsubscribe: '/o/email-notification/v1.0/unsubscribe/true/userId',
  resubscribe: '/o/email-notification/v1.0/unsubscribe/false/userId',
  insproservice: '/myaccount-core-service/api/v1/utility/serviceStatus',
  registerMigration: '/o/migration/v1.0/',
  getPayNow: '/o/payments/v1.0/payment/paynow',
  getPayNowHomePage: '/myaccount-core-service/api/v1/homepage/paynow',
  getPaymentInfo: '/o/payments/v1.0/paymentAmountBreakdown',
  getUpdatedFrequency: '/o/payments/v1.0/autoPaymentPreference',
  getPagesTitle: '/agia-appcontent-service/api/v1/contentStructure',
  updateLoginInformations: '/o/authentication/v1.0/updateLoginInformations'
};

export const byPassCsUserId = [
  apiRequest.base + apiRequest.getContents,
];

export const SETNRTY_CONFIG = { 
  sentryDSN: process.env.REACT_APP_SENTRY_DSN, 
  sentryENV: process.env.REACT_APP_ENV, 
} 