import { HttpRequest } from './http/http.service';
import { apiRequest } from '../_constants/url.constants';

export class PaymentCenterService {
    http = new HttpRequest();

    getPaymentMethods(params) {
        return this.http.post(apiRequest.base + apiRequest.getPaymentMethods, params);
    }

    addPaymentMethod(params) {
        return this.http.post(apiRequest.base + apiRequest.addPaymentMethod, params);
    }

    updatePaymentMethod(params) {
        return this.http.put(apiRequest.base + apiRequest.updatePaymentMethod, params);
    }

    deletePaymentMethod(params) {
        return this.http.post(apiRequest.base + apiRequest.deletePaymentMethod, params);
    }

    getProducts(params) {
        return this.http.post(apiRequest.base + apiRequest.getProducts, params);
    }

    changeFrequencyAutopay(params) {
        return this.http.post(apiRequest.base + apiRequest.changeFrequencyAutopay, params);
    }

    submitPayNow(params) {
        return this.http.post(apiRequest.base + apiRequest.submitPayNow, params);
    }

    getPayNow(params, type) {
        let url = type === 'DASHBOARD' ? apiRequest.getPayNowHomePage : apiRequest.getPayNow
        return this.http.post(apiRequest.base + url, params);
    }

    getPaymentInfo(params) {
        return this.http.post(apiRequest.base + apiRequest.getPaymentInfo, params);
    }

    getUpdatedFrequency(params) {
        return this.http.post(apiRequest.base + apiRequest.getUpdatedFrequency, params);
    }
}